// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-robots-txt-js": () => import("/opt/build/repo/src/pages/robots.txt.js" /* webpackChunkName: "component---src-pages-robots-txt-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-your-idea-js": () => import("/opt/build/repo/src/pages/your-idea.js" /* webpackChunkName: "component---src-pages-your-idea-js" */)
}

